<template>
  <v-container>
    <v-row>
      <v-col md="2" sm="3" lg="2">
        <v-select
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          background-color="#fff"
          light
          :items="venueServices"
          item-text="name"
          item-value="venue_service_id"
          v-model="venueServiceId"
          @change="loadConfigurations"
          label="Service name"
          v-if="tab == 'packages' || tab == 'levels'"
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col lg="6" md="6" sm="5" style="text-align: center">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          centered
          slider-color="transparent"
          icons-and-text
          active-class="tab_active"
        >
          <v-tab href="#packages" style="min-width: 100px">
            Packages
            <v-icon color="#066a8c">mdi-package-variant-closed</v-icon>
          </v-tab>
          <v-tab href="#levels" style="min-width: 100px">
            Levels
            <v-icon color="#066a8c">mdi-palette</v-icon>
          </v-tab>
          <v-tab href="#fields" style="min-width: 100px">
            fields
            <v-icon color="#066a8c">mdi-text-account</v-icon>
          </v-tab>
          <v-tab href="#documents" style="min-width: 100px">
            Documents
            <v-icon color="#066a8c">mdi-file-document-multiple </v-icon>
          </v-tab>
          <v-tab href="#tags" style="min-width: 100px">
            Quick Scan
            <v-icon color="#066a8c">mdi-tag-outline </v-icon>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <div style="float: right">
          <v-btn large @click="gotoTrainer" right>
            <v-icon small>mdi-backburger</v-icon>Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item value="packages">
        <v-form ref="pform">
          <div class="titles">Packages For All Trainers</div>
          <v-card
            v-for="(trainerPackage,
            pIndex) in trainingServices.training_packages"
            :key="`ts_${pIndex}`"
            outlined
            class="mb-4"
            :style="cardStyle"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="trainingServices.training_packages.length > 1"
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  class="mt-1"
                  absolute
                  right
                  top
                  @click="removeTrainingPackage(pIndex)"
                  fab
                  x-small
                  dark
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              Delete
            </v-tooltip>
            <v-container>
              <v-row>
                <v-col md="3">
                  <v-text-field
                    label="Name*"
                    required
                    v-model="trainerPackage.name"
                    :rules="[(v) => !!v || 'Package name is required']"
                    outlined
                    background-color="#fff"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-select
                    label="Type*"
                    required
                    v-model="trainerPackage.package_type"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    :items="packageTypes"
                    @change="setPackageTypePeople(pIndex)"
                    item-text="name"
                    item-value="type"
                    :rules="[(v) => !!v || 'Type is required']"
                  ></v-select>
                </v-col>
                <v-col md="3" v-if="trainerPackage.package_type == 'G'">
                  <v-text-field
                    label="People*"
                    required
                    v-model="trainerPackage.people"
                    :rules="[
                      (v) => !!v || 'No persons is required',
                      (v) => !isNaN(v) || 'Price must be Number',
                    ]"
                    outlined
                    background-color="#fff"
                  ></v-text-field>
                </v-col>

                <v-col md="3">
                  <v-select
                    label="Duration"
                    required
                    v-model="trainerPackage.duration"
                    hint="Session duration (default 1 hr)"
                    :items="getMinBookingTimes()"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                  ></v-select>
                </v-col>
                <v-col
                  :md="
                    trainerPackage.package_type == 'G' &&
                    checkWritePermission($modules.trainers.packageValidity.slug)
                      ? 2
                      : 3
                  "
                >
                  <v-text-field
                    label="Sessions*"
                    required
                    v-model="trainerPackage.sessions"
                    :rules="[
                      (v) => !!v || 'Sessions is required',
                      (v) => !isNaN(v) || 'Sessions must be Number',
                    ]"
                    outlined
                    background-color="#fff"
                  ></v-text-field>
                </v-col>

                <v-col
                  v-if="
                    checkWritePermission($modules.trainers.packageValidity.slug)
                  "
                  md="trainerPackage.package_type == 'G' && checkWritePermission($modules.trainers.packageValidity.slug) ? 2 : 3"
                >
                  <v-text-field
                    label="Validity days*"
                    required
                    v-model="trainerPackage.validity_days"
                    :rules="[
                      (v) => !!v || 'Validity days is required',
                      (v) => !isNaN(v) || 'Validity days must be Number',
                    ]"
                    outlined
                    background-color="#fff"
                  ></v-text-field>
                </v-col>

                <v-col
                  :md="
                    trainerPackage.package_type == 'G' &&
                    checkWritePermission($modules.trainers.packageValidity.slug)
                      ? 2
                      : 3
                  "
                >
                  <v-autocomplete
                    @change="taxChange(trainerPackage)"
                    v-model="trainerPackage.tax_type_id"
                    :items="taxTypes"
                    clearable
                    label="Tax type*"
                    item-text="text"
                    item-value="value"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'Tax type is required']"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    label="Price (Pre Tax)*"
                    @change="
                      calculateTaxVariation($event, trainerPackage, 'pre')
                    "
                    required
                    v-model="trainerPackage.pre_tax_price"
                    :rules="[
                      (v) => !!v || 'Price is required',
                      (v) => !isNaN(v) || 'Price must be Number',
                    ]"
                    :prefix="currencyCode"
                    outlined
                    background-color="#fff"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    label="Price (Post Tax)*"
                    @change="
                      calculateTaxVariation($event, trainerPackage, 'post')
                    "
                    required
                    v-model="trainerPackage.total_price"
                    :rules="[
                      (v) => !!v || 'Price is required',
                      (v) => !isNaN(v) || 'Price must be Number',
                    ]"
                    :prefix="currencyCode"
                    outlined
                    background-color="#fff"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <div class="add_btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="teal"
                  fab
                  x-small
                  dark
                  @click="addPackage"
                >
                  <v-icon small>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Add
            </v-tooltip>
          </div>
          <div style="float: right">
            <v-btn
              class="white--text blue-color"
              @click="addOrEditTrainerServices($event)"
              >Update</v-btn
            >
          </div>
        </v-form>
      </v-tab-item>
      <v-tab-item value="levels">
        <v-form ref="cform">
          <div class="titles">Customer levels</div>
          <v-card
            :style="cardStyle"
            v-for="(code, cIndex) in colorCodes"
            :key="`cc_${cIndex}`"
            class="mb-4"
            flat
          >
            <v-container>
              <v-row>
                <v-col :md="colorCodes.length > 1 ? 6 : 7">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="code.name"
                    label="Code name*"
                    :rules="[(v) => !!v || 'Code name is required']"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="code.color_code"
                    background-color="#fff"
                    light
                    :rules="[(v) => !!v || 'Color code is required']"
                  >
                    <template v-slot:append>
                      <v-menu
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle(cIndex)" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                              v-model="code.color_code"
                              flat
                              :swatches="swatches"
                              show-swatches
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col md="1" v-if="colorCodes.length > 1" class="mt-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="del_btn"
                        color="red"
                        @click="deleteCode(cIndex)"
                        x-small
                        dark
                        fab
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <div class="add_btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="blue-color"
                  fab
                  x-small
                  dark
                  @click="addColorCode()"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Add new code
            </v-tooltip>
          </div>
          <div style="float: right">
            <v-btn
              class="white--text blue-color"
              @click="saveColorCodes($event)"
              >Update</v-btn
            >
          </div>
        </v-form>
      </v-tab-item>
      <v-tab-item value="fields">
        <div class="titles mt-6">Preferences</div>
        <v-card outlined class="mb-8" :style="cardStyle">
          <v-card-text>
            <v-row>
              <v-col lg="4" md="4">
                <v-checkbox
                  v-model="configurations.trainer_configurations.tnc_enable"
                  class="mt-2"
                  false-value="0"
                  true-value="1"
                  label="Enable Website T&C's"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                lg="3"
                md="3"
                v-if="
                  checkWritePermission($modules.trainers.resetCommission.slug)
                "
              >
                <v-select
                  label="Reset Trainer Commission"
                  required
                  v-model="
                    configurations.trainer_configurations
                      .commission_reset_interval
                  "
                  :items="commissionResetInterval"
                  outlined
                  item-text="name"
                  item-value="type"
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row
          class="mt-10 mb-10"
          style="border: 1px solid black;"
          v-if="configurations.trainer_configurations.tnc_enable == 1"
        >
          <v-card style="width: 100%; min-width: 300px">
            <h2 class="pa-4">Terms and Conditions</h2>
            <div
              class="px-4 pb-4 d-flex flex-row flex-wrap"
              style="width: 100%"
            >
              <text-editor
                @complete="setTncContent"
                :message="configurations.trainer_configurations.tnc"
                style="width: 100%"
              />
            </div>
          </v-card>
        </v-row>
        <div class="titles">Booking Configurations</div>
        <v-divider></v-divider>
        <v-card class="mb-8" :style="cardStyle">
          <v-row>
            <v-col>
              <table class="settings_table">
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>View</th>
                    <th>Mandatory</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(config,
                    index) in configurations.field_configurations"
                    :key="index"
                  >
                    <td>{{ config.name }}</td>
                    <td>
                      <v-btn
                        icon
                        :color="config.is_visible == 1 ? 'success' : 'error'"
                        @click="checkAsVisible(index)"
                      >
                        <v-icon
                          :style="
                            viewRestrictedFields.includes(config.slug) &&
                              'cursor:not-allowed;'
                          "
                        >
                          {{
                            config.is_visible == 1 ? "mdi-check" : "mdi-close"
                          }}
                        </v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        icon
                        :color="config.is_required == 1 ? 'success' : 'error'"
                        @click="checkAsMandatory(index)"
                      >
                        <v-icon
                          :style="
                            mandatoryRestrictedFields.includes(config.slug) &&
                              'cursor:not-allowed;'
                          "
                        >
                          {{
                            config.is_required == 1 ? "mdi-check" : "mdi-close"
                          }}
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text blue-color"
            @click="saveConfiguration('fields')"
            >Update</v-btn
          >
        </v-card-actions>
      </v-tab-item>
      <v-tab-item value="documents">
        <div class="titles">Documents</div>
        <v-divider></v-divider>
        <v-form ref="conf_form">
          <v-card
            outlined
            class="mb-8"
            :style="cardStyle"
            v-for="(document,
            k) in configurations.field_document_configurations"
            :key="'d_' + k"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Name"
                    required
                    outlined
                    background-color="#fff"
                    v-model="document.name"
                    :rules="[(v) => !!v || 'Document field name is required']"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <v-file-input
                    :label="document.document_id != null ? '' : 'Document'"
                    v-model="document.file"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    outlined
                    background-color="#fff"
                  >
                    <template v-slot:label>
                      <span v-if="!document.document_id"> Select file </span>
                      <span
                        v-if="document.document_id && !document.file"
                        class="font-weight-bold"
                      >
                        <span
                          style="width: 70%; display: inline-block"
                          class="text-truncate"
                          >{{ document.uploaded_original_file_name }}</span
                        >
                        <span
                          style="width: 20%; display: inline-block"
                          class="text-truncate"
                          >.{{
                            document.uploaded_original_file_name.split(".")[
                              document.uploaded_original_file_name.split(".")
                                .length - 1
                            ]
                          }}</span
                        >
                      </span>
                    </template>
                  </v-file-input>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-checkbox
                    v-model="document.is_visible"
                    label="View"
                    color="success"
                    value="document.is_visible"
                    v-bind:false-value="0"
                    v-bind:true-value="1"
                    hide-details
                    @change="
                      !document.is_visible ? (document.is_required = 0) : ''
                    "
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-checkbox
                    v-model="document.is_required"
                    label="Mandatory"
                    color="success"
                    value="document.is_required"
                    hide-details
                    v-bind:false-value="0"
                    v-bind:true-value="1"
                    @change="
                      document.is_required ? (document.is_visible = 1) : ''
                    "
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="1" md="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="position: relative; top: calc(50% - 33px)"
                        v-if="
                          checkDeletePermission(
                            $modules.facility.management.slug
                          )
                        "
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                        class="mt-2"
                        @click="deleteDocuments(k)"
                        fab
                        x-small
                        dark
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div class="add_btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="teal-color"
                  fab
                  x-small
                  dark
                  @click="addDocuments"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Add Document
            </v-tooltip>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="white--text blue-color"
              @click="saveConfiguration('documents')"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-tab-item>
      <v-tab-item value="tags">
        <div class="titles">Quick Scan Tags</div>
        <v-divider></v-divider>
        <v-row class="mt-4">
          <v-col cols="12" lg="12" md="12">
            <v-form ref="cform">
              <v-row>
                <v-col
                    lg="4"
                    md="6"
                    sm="12"
                    cols="12"
                    v-for="(code, cIndex) in colorTagCodes"
                    :key="`index${cIndex}`"
                    style="position: relative"
                >
                  <v-card class="shadow-2">
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12" sm="12" class="pb-0">
                          <label for="">
                            Tag name
                          </label>
                          <v-text-field
                              outlined
                              background-color="#fff"
                              light
                              v-model="code.name"
                              dense
                              hide-details="auto"
                              class="q-text-field shadow-0"
                              required
                              :rules="[(v) => !!v || 'Tag name is required']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <label for="">
                            Color Code
                          </label>
                          <v-text-field
                              outlined
                              v-model="code.color_code"
                              background-color="#fff"
                              light
                              dense
                              hide-details="auto"
                              class="q-text-field shadow-0 color-picker"
                              required
                              :rules="[(v) => !!v || 'Color code is required']"
                          >
                            <template v-slot:append>
                              <v-menu
                                  top
                                  nudge-bottom="105"
                                  nudge-left="16"
                                  :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on }">
                                  <div :style="swatchTagStyle(cIndex)" v-on="on"/>
                                </template>
                                <v-card>
                                  <v-card-text class="pa-0">
                                    <v-color-picker
                                        v-model="code.color_code"
                                        flat
                                        :swatches="swatches"
                                        show-swatches
                                    />
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col md="4" class="pl-0">
                          <v-switch
                              class="mx-4 mt-7"
                              dense
                              hide-details="auto"
                              :false-value="14"
                              :true-value="1"
                              label="Active"
                              required
                              v-model="code.status_id"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>




                  <v-tooltip bottom v-if="colorCodes.length > 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="red"
                          dark
                          elevation="0"
                          fab
                          v-bind="attrs"
                          x-small
                          absolute
                          top
                          style="top:-5px;"
                          right
                          @click="deleteTagCode(cIndex)"
                          v-on="on"
                      >
                        <DeleteIcon/>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </v-col>
              </v-row>
              <div class="add_btn">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="blue-color"
                        fab
                        x-small
                        dark
                        @click="addTagColorCode()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Add new tag
                </v-tooltip>
              </div>
              <div style="float: right">
                <v-btn
                    class="white--text blue-color"
                    @click="saveTagColorCodes($event)"
                >Update
                </v-btn
                >
              </div>
            </v-form>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <confirm-model
            v-bind="confirmModel"
            @confirm="confirmActions"
            @close="confirmModel.id = null"
        ></confirm-model>
      </v-tab-item>
    </v-tabs-items>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import constants from "@/utils/constants";
import TextEditor from "@/components/Marketing/TextEditor";

export default {
  components: { TextEditor },

  data() {
    return {
      colorTagCodes: [
        {
          name: null,
          status_id: 1,
          color_code: "#00AAAAFF",
        },
      ],
      deleted_tag_codes: [],
      colorCodes: [
        {
          venue_service_id: null,
          name: null,
          color_code: "#00AAAAFF",
        },
      ],
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      deleted_codes: [],
      venueServiceId: null,
      trainingServices: [],
      deleted_packages: [],
      packageTypes: [
        { type: "I", name: "Individual" },
        { type: "C", name: "Couple" },
        { type: "G", name: "Group" },
      ],
      tab: "packages",
      configurations: {
        field_configurations: [],
        field_document_configurations: [],
        venue_service_configuration: {},
        deleted_field_documents: [],
        trainer_configurations: {
          tnc_enable: 0,
          tnc: "",
          commission_reset_interval: 0,
        },
      },
      tnc: "",
      viewRestrictedFields: constants.VIEWRESTRICTEDFIELDS,
      mandatoryRestrictedFields: constants.MANTATORYRESTRICTEDFIELDS,
      commissionResetInterval: constants.ResetTrainerCommissionInterval,
    };
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then((data) => {
        if (data.length) {
          this.venueServiceId = data[0].venue_service_id;
          this.loadConfigurations();
        }
      });
    } else {
      this.venueServiceId = this.venueServices[0].venue_service_id;
      this.loadConfigurations();
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    this.getConfiguration();
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },
  methods: {
    addTagColorCode() {
      this.colorTagCodes.push({
        name: null,
        status_id: 1,
        color_code: "#00AAAAFF",
      });
      if (this.$refs.cform) {
        this.$refs.cform.resetValidation();
      }
    },
    swatchTagStyle(cIndex) {
      const {color_code, menu} = this.colorTagCodes[cIndex];
      return {
        backgroundColor: color_code,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    getTagColorCodes() {
      this.$http
          .get(`venues/trainers/configuration`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              if (data.length) {
                this.colorTagCodes = data;
              } else {
                this.colorTagCodes = [
                  {
                    name: null,
                    status_id: 1,
                    color_code: "#00AAAAFF",
                  },
                ];
              }
              this.deleted_tag_codes = [];
              this.$forceUpdate();
              if (this.$refs.cform) {
                this.$refs.cform.resetValidation();
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    deleteTagCode(cIndex) {
      let pkg = this.colorTagCodes[cIndex];
      if (pkg.name == null && pkg.color_code == null && pkg.id == null) {
        this.colorTagCodes.splice(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this Tag?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
          type: "tag_color_code",
        };
      }
    },
    saveTagColorCodes() {
      if (!this.$refs.cform.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let data = {
        color_codes: this.colorTagCodes,
      };
      if (this.deleted_tag_codes.length) {
        data.deleted_codes = this.deleted_tag_codes;
      }
      this.showLoader();
      this.$http
          .post(`venues/trainers/configuration`, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Tags saved");
              this.getColorCodes();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    setTncContent(content) {
      this.tnc = content;
    },
    loadConfigurations() {
      this.getColorCodes();
      this.loadTrainingServices();
      this.getTagColorCodes();
    },
    swatchStyle(cIndex) {
      const { color_code, menu } = this.colorCodes[cIndex];
      return {
        backgroundColor: color_code,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    addColorCode() {
      this.colorCodes.push({
        name: null,
        venue_service_id: this.venueServiceId,
        color_code: "#00AAAAFF",
      });
      if (this.$refs.cform) {
        this.$refs.cform.resetValidation();
      }
    },
    getColorCodes() {
      this.$http
        .get(
          `venues/general/color-codes/trainer?venue_service_id=${this.venueServiceId}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (data.length) {
              this.colorCodes = data;
            } else {
              this.colorCodes = [
                {
                  name: null,
                  venue_service_id: this.venueServiceId,
                  color_code: "#00AAAAFF",
                },
              ];
            }
            this.deleted_codes = [];
            this.$forceUpdate();
            if (this.$refs.cform) {
              this.$refs.cform.resetValidation();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    saveColorCodes() {
      if (!this.$refs.cform.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let data = {
        color_codes: this.colorCodes,
      };
      if (this.deleted_codes.length) {
        data.deleted_codes = this.deleted_codes;
      }
      this.showLoader();
      this.$http
        .post(`venues/general/color-codes/trainer`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Color codes saved");
            this.getColorCodes();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    deleteCode(cIndex) {
      let pkg = this.colorCodes[cIndex];
      if (pkg.name == null && pkg.color_code == null && pkg.id == null) {
        this.colorCodes(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this color code?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "color_code",
        };
      }
    },
    confirmActions(data) {
      if (data.type === "tag_color_code") {
        let index = data.id;
        if (this.colorCodes[index].id != null) {
          this.deleted_codes.push(this.colorCodes[index].id);
        }
        this.colorCodes.splice(index, 1);
        this.$forceUpdate();
      }
      if (data.type == "color_code") {
        let index = data.id;
        if (this.colorCodes[index].id != null) {
          this.deleted_codes.push(this.colorCodes[index].id);
        }
        this.colorCodes.splice(index, 1);
        this.$forceUpdate();
      } else if (data.type == "package") {
        let index = data.id;
        if (this.trainingServices.training_packages[index].id != null) {
          this.deleted_packages.push(
            this.trainingServices.training_packages[index].id
          );
        }
        this.trainingServices.training_packages.splice(index, 1);
        this.$forceUpdate();
      }

      if (data.type == "delete_document") {
        if (
          this.configurations.field_document_configurations[data.id].id != null
        ) {
          this.configurations.deleted_field_documents.push(
            this.configurations.field_document_configurations[data.id].id
          );
        }
        this.configurations.field_document_configurations.splice(data.id, 1);
        if (!this.configurations.field_document_configurations.length) {
          this.configurations.field_document_configurations = [{}];
        }
      }
      this.confirmModel.id = null;
    },
    loadTrainingServices() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/trainers/services?venue_service_id=${this.venueServiceId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (data.length) {
              this.trainingServices = data[0];
            } else {
              this.trainingServices = {
                venue_service_id: this.venueServiceId,
                training_packages: [
                  {
                    venue_service_id: this.venueServiceId,
                    name: null,
                    sessions: null,
                    validity_days: null,
                    package_type: null,
                    price: null,
                  },
                ],
              };
            }
            this.deleted_services = [];
            this.deleted_packages = [];
            this.hideLoader();
            if (this.$refs.pform) {
              this.$refs.pform.resetValidation();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    setPackageTypePeople(index) {
      let pkgType = this.trainingServices.training_packages[index].package_type;
      if (pkgType == "I") {
        this.trainingServices.training_packages[index].people = 1;
      } else if (pkgType == "C") {
        this.trainingServices.training_packages[index].people = 2;
      } else if (pkgType == "G") {
        this.trainingServices.training_packages[index].people = null;
      }
    },
    removeTrainingPackage(index) {
      let pkg = this.trainingServices.training_packages[index];
      if (pkg.name == null && pkg.sessions == null && pkg.price == null) {
        this.trainingServices.training_packages.splice(index, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this package?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "package",
        };
      }
    },
    addPackage() {
      this.trainingServices.training_packages.push({
        name: null,
        session: null,
        package_type: null,
        price: null,
        tax_type_id: null,
      });
      if (this.$refs.pform) {
        this.$refs.pform.resetValidation();
      }
    },
    addOrEditTrainerServices() {
      if (!this.$refs.pform.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let postData = {
        training_services: [this.trainingServices],
      };
      if (this.deleted_services.length) {
        postData.deleted_services = this.deleted_services;
      }
      if (this.deleted_packages.length) {
        postData.deleted_packages = this.deleted_packages;
      }
      this.showLoader();
      this.$http
        .post(`venues/trainers/services`, postData)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Training packages updated successfully");
            this.hideLoader();
            this.loadTrainingServices();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    gotoTrainer() {
      this.$router.push({
        name: "Trainers",
      });
    },
    getMinBookingTimes() {
      let duration = [];
      const venueService = this.venueServices.find(
        (item) => item.venue_service_id == this.venueServiceId
      );
      const timeIncrement = venueService ? venueService.time_increment : null;
      if (timeIncrement) {
        let hour = 0;
        let index = 0;
        while (hour < 10) {
          index = index + 1;
          let time = timeIncrement * index;
          hour = parseInt(time / 60);
          let min =
            time / 60 - hour > 0 ? Math.round((time / 60 - hour) * 60) : 0;
          let text = hour > 0 ? `${hour} Hour ` : "";
          text += min > 0 ? `${min} Min` : "";
          duration.push({ value: time, text: text });
        }
      }
      return duration;
    },

    calculateTaxVariation(amount, trainerPackage, type) {
      let taxTypeId = trainerPackage.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
          .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      trainerPackage.price = priceData.price;
      trainerPackage.pre_tax_price = priceData.price.toFixed(2);
      trainerPackage.total_price = priceData.total_price;
      this.$forceUpdate();
    },

    taxChange(trainerPackage) {
      if (trainerPackage.price) {
        this.calculateTaxVariation(trainerPackage.price, trainerPackage, "pre");
      } else if (trainerPackage.total_price) {
        this.calculateTaxVariation(
          trainerPackage.total_price,
          trainerPackage,
          "post"
        );
      }
    },

    checkAsMandatory(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.mandatoryRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_required =
          field.is_required == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_visible = 1;
        }
      }
    },
    checkAsVisible(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.viewRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_visible =
          field.is_visible == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_required = 0;
        }
      }
    },

    getConfiguration() {
      this.showLoader();
      this.$store
        .dispatch("loadConfigurations", "Trainer")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.configurations = response.data.data;

            if (this.configurations.field_document_configurations.length == 0) {
              this.configurations.field_document_configurations.push({
                name: null,
                file: null,
                is_required: 0,
                is_visible: 0,
              });
            } else {
              this.configurations.field_document_configurations.forEach(
                (element) => {
                  if (!element.document_id) {
                    element.file = null;
                  }
                }
              );
            }
            if (
              this.configurations.trainer_configurations &&
              this.configurations.trainer_configurations.tnc_enable
            ) {
              this.configurations.trainer_configurations.tnc_enable = this.configurations.trainer_configurations.tnc_enable.toString();
            }

            this.tnc = this.configurations.trainer_configurations.tnc;
            this.configurations.deleted_field_documents = [];
            this.$forceUpdate();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },

    saveConfiguration(type) {
      let formData = new FormData();
      formData.append(`product_type`, "Trainer");
      if (type == "fields") {
        this.configurations.field_configurations.forEach((item, index) => {
          for (let key in item) {
            if (item.product_type_id == 6) {
              delete item.product_type_id;
              delete item.id;
            }
            if (item[key] != null) {
              formData.append(
                `field_configurations[${index}][${key}]`,
                item[key]
              );
            }
          }
        });
      }

      if (type == "documents") {
        if (this.configurations.field_document_configurations.length) {
          this.configurations.field_document_configurations.forEach(
            (item, index) => {
              if (item.name) {
                for (let key in item) {
                  formData.append(
                    `field_document_configurations[${index}][${key}]`,
                    item[key]
                  );
                }
              }
            }
          );
        }
      }

      if (this.configurations.deleted_field_documents.length) {
        this.configurations.deleted_field_documents.forEach((item, index) => {
          formData.append(`deleted_field_documents[${index}]`, item);
        });
      }

      formData.append(
        `trainer_tnc_enable`,
        this.configurations.trainer_configurations.tnc_enable
      );
      if (this.configurations.trainer_configurations.tnc_enable == 1) {
        formData.append(`trainer_tnc`, btoa(this.sanitizeHTML(this.tnc)));
      }
      let commission_reset_interval =
        this.configurations.trainer_configurations.commission_reset_interval ??
        0;
      formData.append(`commission_reset_interval`, commission_reset_interval);

      this.showLoader();
      this.$http({
        method: "post",
        data: formData,
        url: "venues/configurations",
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.showSuccess("Configuration successfully updated");
            this.$store.dispatch("loadConfigurations", "Trainer").then(() => {
              this.$nextTick(() => {
                this.$forceUpdate;
              });
            });
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.$store.dispatch("loadVenueServices");
          this.$store.dispatch("loadConfigurations", "Trainer");
        });
    },

    addDocuments() {
      this.configurations.field_document_configurations.push({
        name: null,
        file: null,
        is_visible: 1,
        is_required: 1,
      });
    },

    deleteDocuments(index) {
      if (
        !this.configurations.field_document_configurations[index].name &&
        !this.configurations.field_document_configurations[index].id &&
        !this.configurations.field_document_configurations[index]
          .document_type_id
      ) {
        this.configurations.field_document_configurations.splice(index, 1);
        if (!this.configurations.field_document_configurations.length) {
          this.configurations.field_document_configurations = [{}];
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this custom field?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_document",
        };
      }
    },
  },
};
</script>

<style>
.settings_table {
  width: 100%;
  text-align: center !important;
}
.settings_table th {
  text-align: center !important;
}
.settings_table td,
.settings_table th {
  border: 1px solid #ddd;
  padding: 8px;
}
.settings_table tr {
  background-color: #f2f2f2;
}
.settings_table tbody td:first-child {
  font-weight: bolder;
  font-size: 13px;
}
.settings_table tr:hover {
  background-color: #ddd;
}
.settings_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #066a8c;
  color: white;
}
 .color-picker .v-input__append-inner {
   margin-top: 6px !important;
 }
</style>
